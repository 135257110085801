import { request } from "./request";

//添加购物车
export function addToCart(options, goods_id, num) {
    return request({
        url: '/api/cart/addToCart',
        method: 'post',
        data: {
            options,
            goods_id,
            num,
        }
    })
};
//请求购物车列表
export function getCartlist() {
    return request({
        url: '/api/cart/getCartlist',
    })
};
//提交购物车内容
export function plusOneCart(cartid) {
    return request({
        url: '/api/cart/plusOneCart',
        params: {
            cartid: cartid,
        }
    })
};
//支付购物车内容
export function reduceOneCart(cartid) {
    return request({
        url: '/api/cart/reduceOneCart',
        params: {
            cartid: cartid,
        }
    })
};


//支付购物车内容
export function delCartByid(cartid) {
    return request({
        url: '/api/cart/delCartByid',
        params: {
            cartid: cartid,
        }
    })
};


//添加购物车  自定义条数
export function changeCartNumByid(cartid, num) {
    return request({
        url: '/api/cart/changeCartNumByid',
        params: {
            cartid: cartid,
            num: num,
        }
    })
};